import { Avatar as MuiAvatar } from '@material-ui/core';
import { MouseEventHandler } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { ProBadge } from '@shared/components/pro-badge';
import { Tooltip, TooltipProps } from '@shared/components/tooltip';
import { ITheme } from '@shared/models/theme';
import { colors } from '@shared/theme/colours';
import { getInitials } from '@shared/utils/common';

import { styles } from './Avatar.styles';

const getInitialsFontSize = (size: number) => {
  if (size <= 24) return 10;
  if (size <= 32) return 13;
  if (size <= 40) return 16;
  if (size <= 48) return 19;
  if (size <= 56) return 22;
  if (size <= 64) return 25;
  if (size <= 72) return 28;
  if (size <= 80) return 31;

  return 34;
};

export interface AvatarProps extends WithStyles<typeof styles> {
  image: string | null;
  isPro?: boolean;
  name: string;
  initials?: string;
  size: number;
  style?: React.HTMLAttributes<HTMLDivElement>['style'];
  theme?: ITheme;
  tooltip?: TooltipProps['title'];
  tooltipPlacement?: TooltipProps['placement'];
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

const AvatarComponent: React.FC<AvatarProps> = ({
  classes,
  image,
  isPro,
  name,
  initials,
  size,
  style,
  theme = { backgroundColour: null, textColour: null },
  tooltip,
  tooltipPlacement = 'top',
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const content = (
    <>
      {image ? (
        <MuiAvatar
          alt={`${name} - Avatar image`}
          className={classes.avatar}
          src={image}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      ) : (
        <div className={classes.noImage}>
          <Flex
            alignItems="center"
            className={classes.noImageInitials}
            justifyContent="center"
            style={{
              backgroundColor: theme.backgroundColour || colors.blue.dark,
              color: theme.textColour || colors.white.lightest,
              fontSize: getInitialsFontSize(size),
            }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {initials || getInitials(name)}
          </Flex>
        </div>
      )}
      {isPro && <ProBadge classes={{ root: classes.proBadge }} />}
    </>
  );

  const rootStyles = { ...style, height: size, minWidth: size, width: size };

  if (tooltip) {
    return (
      <Tooltip
        classes={{ container: classes.root, tooltip: classes.tooltip }}
        placement={tooltipPlacement}
        style={rootStyles}
        title={tooltip}
      >
        {content}
      </Tooltip>
    );
  }

  return (
    <div className={classes.root} style={rootStyles}>
      {content}
    </div>
  );
};

export const Avatar = withStyles(styles)(AvatarComponent);
