import { InputAdornmentProps } from '@material-ui/core/InputAdornment';
import { PopoverProps as PopoverPropsType } from '@material-ui/core/Popover';
import { TextFieldProps } from '@material-ui/core/TextField';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import cx from 'classnames';
import React, { useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FieldHelperText } from '@shared/components/field-helper-text';
import { Flex } from '@shared/components/flex';
import { Tooltip, TooltipProps } from '@shared/components/tooltip/Tooltip';
import { InputSize } from '@shared/types/common/input';
import { DATE_TIME_PICKER_FORMAT, PICKER_DATE_FORMAT } from '@shared/utils/date';

import { styles } from './DatePicker.styles';

export type MaterialDate = MaterialUiPickersDate | string | null;

export interface DatePickerProps extends WithStyles<typeof styles> {
  autoWidth?: boolean;
  dateFormat?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  label?: string;
  minDate?: MaterialDate | Date;
  placeholder: string;
  size?: InputSize;
  tooltip?: string;
  tooltipArrow?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  type?: 'date' | 'dateAndTime';
  value: MaterialDate | Date;
  onChange: (date: MaterialDate | null, value: string | null) => void;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
  autoWidth = true,
  classes,
  dateFormat,
  disabled = false,
  disableFuture = false,
  disablePast = false,
  error,
  errorText,
  fullWidth = false,
  label,
  minDate,
  placeholder,
  size = InputSize.mediumLegacy,
  tooltip,
  tooltipArrow,
  tooltipPlacement,
  type = 'date',
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const className = cx(
    classes.picker,
    classes[size],
    { [classes.pickerFullWidth]: fullWidth },
    { [classes.pickedDisabled]: disabled }
  );

  // Configure date icon
  const InputAdornmentProps: Partial<InputAdornmentProps> = {
    position: 'start',
  };

  const pickerOnChange = (date: MaterialUiPickersDate | null, value?: string | null) => {
    onChange(date ?? null, value ?? null);
    setOpen(false);
  };

  // Input props
  const inputProps: TextFieldProps['inputProps'] = {};
  const InputProps: TextFieldProps['InputProps'] = {
    // Clear icon
    endAdornment: value ? (
      <CancelOutlinedIcon
        onClick={(e) => {
          e.stopPropagation();
          onChange(null, null);
        }}
        classes={{ root: classes.clearIcon }}
      />
    ) : undefined,
  };

  // Popover props
  const PopoverProps: Partial<PopoverPropsType> = {
    onClose: () => setOpen(false),
  };

  const fieldContent =
    type === 'date' ? (
      <KeyboardDatePicker
        className={className}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        error={error}
        format={dateFormat || PICKER_DATE_FORMAT}
        InputAdornmentProps={InputAdornmentProps}
        InputProps={InputProps}
        inputProps={inputProps}
        inputVariant="outlined"
        margin="none"
        minDate={minDate}
        open={open}
        placeholder={placeholder}
        PopoverProps={PopoverProps}
        size="small"
        value={value}
        variant="inline"
        onChange={pickerOnChange}
        onClick={() => setOpen(true)}
      />
    ) : (
      <KeyboardDateTimePicker
        className={className}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        error={error}
        format={dateFormat || DATE_TIME_PICKER_FORMAT}
        hideTabs={true}
        InputAdornmentProps={InputAdornmentProps}
        InputProps={InputProps}
        inputProps={inputProps}
        inputVariant="outlined"
        margin="none"
        minDate={minDate}
        open={open}
        placeholder={placeholder}
        size="small"
        value={value}
        variant="inline"
        onChange={pickerOnChange}
        onClick={() => setOpen(true)}
      />
    );

  const content = tooltip ? (
    <Tooltip arrow={tooltipArrow} placement={tooltipPlacement || undefined} title={tooltip}>
      {fieldContent}
    </Tooltip>
  ) : (
    fieldContent
  );

  return (
    <Flex autoWidth={autoWidth} direction="column" classes={{ root: classes.root }}>
      {label && <span className={classes.label}>{label}</span>}
      <Flex direction="column" autoWidth={false}>
        {content}
        <FieldHelperText error={error} errorText={errorText} />
      </Flex>
    </Flex>
  );
};

export const DatePicker = withStyles(styles)(DatePickerComponent);
