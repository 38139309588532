import { LinearProgress } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { Toast, ToastProps } from '@shared/components/toast';
import { PusherKeyUser, PusherPayload } from '@shared/types/common/events';

import { styles } from './BulkActionProgressToast.styles';

export interface BulkActionProgressToastProps
  extends Omit<ToastProps, 'classes'>,
    Omit<PusherPayload[PusherKeyUser.BulkActionProgress], 'job_id' | 'type'>,
    WithStyles<typeof styles> {}

const BulkActionProgressToastComponent: FC<BulkActionProgressToastProps> = ({
  classes,
  progress,
  subtext,
  title,
  onClose,
}) => {
  return (
    <Toast classes={{ root: classes.root }} title={title} onClose={onClose}>
      <Flex autoWidth={false} classes={{ root: classes.content }} direction="column">
        <span className={classes.message}>{subtext}</span>
        <div className={classes.progressWrapper}>
          <LinearProgress classes={{ root: classes.progress }} value={progress} variant="determinate" />
        </div>
      </Flex>
    </Toast>
  );
};

export const BulkActionProgressToast = withStyles(styles)(BulkActionProgressToastComponent);
