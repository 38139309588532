import { alpha } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export function styles({ spacing }: Theme) {
  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    rootAbsolute: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3,
      backgroundColor: alpha(colors.white.lightest, 0.5),
    },
    none: {
      margin: 0,
    },
    small: {
      margin: spacing(2),
    },
    normal: {
      margin: spacing(4),
    },
    big: {
      margin: spacing(6),
    },
    svg: {
      color: colors.kato.navy[900],
    },
  });
}
