import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';
export const navMenuWidth = 210;
export const navMenuWidthCollapsed = 24;

export const styles = ({ spacing, zIndex }: Theme) =>
  createStyles({
    root: {},

    sideBar: {
      height: '100%',
      minHeight: 640,
      position: 'fixed',
      transition: 'all 0.3s ease',
      width: navMenuWidth,
      zIndex: zIndex.appBar,
    },
    sideNavigationCollapsed: {
      width: navMenuWidthCollapsed,
    },

    app: {
      backgroundColor: colors.grey.body,
      minHeight: '100vh',
      zIndex: 1,
    },
    appNotFullScreen: {
      height: 'auto',
      marginLeft: navMenuWidth,
      transition: 'all 0.3s ease',
    },
    appFullScreen: {},
    appCollapsed: {
      marginLeft: navMenuWidthCollapsed,
    },
    appNoTransition: {
      transition: 'none',
    },
    toasts: {
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(2),
      justifyContent: 'flex-end',
      left: 0,
      margin: spacing(2),
      overflowY: 'auto',
      position: 'fixed',
      width: 400,
      zIndex: 10000,
    },
  });
