import {
  IconButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import cx from 'classnames';
import { forwardRef, ReactElement, Ref, useMemo } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button-new';
import { ConfirmationButtonsProps } from '@shared/components/confirmation';
import { Flex } from '@shared/components/flex';
import { Loading } from '@shared/components/loading';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';

import { styles } from './Dialog.styles';

export enum DialogTheme {
  legacy = 'legacy',
  new = 'new',
}

const DialogTransition = forwardRef(
  (props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
  )
);

export interface DialogHeadingProps extends Omit<MuiDialogTitleProps, 'classes'> {
  heading?: React.ReactNode;
  headingActions?: React.ReactNode;
  withCloseButton?: boolean;
  onClose?: () => any;
}

export interface DialogProps extends WithStyles<typeof styles>, Omit<MuiDialogProps, 'classes'> {
  actionNode?: React.ReactElement;
  buttonProps?: ConfirmationButtonsProps;
  error?: string;
  headingProps?: DialogHeadingProps;
  loading?: boolean;
  theme?: DialogTheme;
  withFooter?: boolean;
  withTransition?: boolean;
}

const DialogComponent: React.FC<DialogProps> = ({
  actionNode,
  buttonProps,
  children,
  classes,
  error,
  headingProps,
  open,
  loading = false,
  theme = DialogTheme.new,
  withFooter = true,
  withTransition = false,
  onClose,
  onSubmit,
  ...otherProps
}) => {
  const handleCancel = () => {
    if (onClose) {
      onClose('', 'backdropClick');
    }
  };

  const handleSubmit = (e: any) => {
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const renderActionButtons = () => {
    if (actionNode) {
      return (
        <MuiDialogActions classes={{ root: classes.actions }}>
          {error && <span className={classes.actionsError}>{error}</span>}
          <Flex justifyContent="space-between" classes={{ root: classes.actionButtons }}>
            {actionNode}
            <div>
              {buttonProps?.cancel && (
                <Button
                  text={buttonProps?.cancel?.text || t('cancel')}
                  colour={ButtonColour.secondary}
                  size={ButtonSize.mediumLegacy}
                  onClick={handleCancel}
                  classes={{ root: classes.cancel }}
                  {...buttonProps.cancel}
                />
              )}

              <Button
                colour={ButtonColour.primary}
                size={ButtonSize.mediumLegacy}
                text={t('apply')}
                onClick={handleSubmit}
                {...buttonProps?.confirm}
              />
            </div>
          </Flex>
        </MuiDialogActions>
      );
    }

    return (
      <MuiDialogActions classes={{ root: classes.actions }}>
        {error && <span className={classes.actionsError}>{error}</span>}
        <Flex justifyContent="space-between" classes={{ root: classes.actionButtons }}>
          <Button
            text={buttonProps?.cancel?.text || t('cancel')}
            colour={ButtonColour.secondary}
            size={ButtonSize.mediumLegacy}
            onClick={handleCancel}
            {...buttonProps?.cancel}
          />
          <Button
            colour={ButtonColour.primary}
            size={ButtonSize.mediumLegacy}
            text={t('apply')}
            onClick={handleSubmit}
            {...buttonProps?.confirm}
          />
        </Flex>
      </MuiDialogActions>
    );
  };

  const body = useMemo(() => {
    if (theme === DialogTheme.legacy) {
      return children;
    }

    return <div className={classes.body}>{children}</div>;
  }, [children, classes, theme]);

  const content = useMemo(() => {
    if (loading) {
      return <Loading absolute />;
    }

    return (
      <>
        {headingProps && (
          <MuiDialogTitle disableTypography className={classes.titleRoot}>
            <Flex alignItems="center" justifyContent="space-between" classes={{ root: classes.title }} wrap="nowrap">
              {headingProps.heading && <h1 className={classes.heading}>{headingProps.heading}</h1>}
              <Flex alignItems="center" classes={{ root: classes.headingActions }} wrap="nowrap">
                {headingProps.headingActions}
                {headingProps.withCloseButton && onClose && (
                  <IconButton disableRipple className={classes.closeIcon} onClick={handleCancel}>
                    <CloseIcon classes={{ root: classes.closeIconIcon }} />
                  </IconButton>
                )}
              </Flex>
            </Flex>
          </MuiDialogTitle>
        )}
        {body}
        {withFooter && renderActionButtons()}
      </>
    );
  }, [body, headingProps, loading, withFooter]);

  return (
    <MuiDialog
      {...otherProps}
      open={open}
      {...(withTransition && { TransitionComponent: DialogTransition })}
      onClose={onClose}
      classes={{ root: classes.rootContainer, paper: cx(classes.root, classes[theme]) }}
    >
      {content}
    </MuiDialog>
  );
};

export const Dialog = withStyles(styles)(DialogComponent);
