import { debounce } from '@material-ui/core';
import { LoadScript } from '@react-google-maps/api';
import cx from 'classnames';
import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { AppId, useConfigAll } from '@core/contexts/ConfigContext';
import { usePusher } from '@core/contexts/PusherContext';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Loading } from '@shared/components/loading';
import { SideNavigation } from '@shared/components/side-navigation';
import { breakpointValues } from '@shared/theme/units/breakpoints';

import { styles } from './Layout.styles';

const calculateNavMenuCollapsed = (previousValue: boolean) => {
  if (window.innerWidth < breakpointValues.md) {
    return true;
  }

  if (window.innerWidth >= breakpointValues.md && previousValue) {
    return false;
  }

  return previousValue;
};

export interface LayoutProps extends WithStyles<typeof styles> {
  googleMapsApiKey: string;
  disableTransition?: boolean;
}

const LayoutComponent: FC<LayoutProps> = ({ children, classes, googleMapsApiKey, disableTransition = false }) => {
  const { appId, sideNavCollapsed, setSideNavCollapsed } = useConfigAll();
  const location = useLocation();
  const { toasts } = usePusher();

  const fullScreenEnabled = useMemo(() => {
    switch (appId) {
      case AppId.Agency:
        // TODO: Check agency ROUTES file
        return location.pathname.startsWith('/present');

      case AppId.Landlord:
        // TODO: Check landlord ROUTES file
        return location.pathname.startsWith('/present');
    }
  }, [appId, location.pathname]);

  useEffect(() => {
    if (fullScreenEnabled) {
      return undefined;
    }

    const handleResize = () => {
      setSideNavCollapsed((prev) => calculateNavMenuCollapsed(prev));
    };

    window.addEventListener('resize', debounce(handleResize, 50));

    return () => window.removeEventListener('resize', handleResize);
  }, [fullScreenEnabled]);

  return (
    // TODO: Helmet - https://www.npmjs.com/package/react-helmet-async
    <LoadScript id="google-map-script" googleMapsApiKey={googleMapsApiKey} loadingElement={<Loading absolute />}>
      {/* NOTE: Hide side navigation when in full screen mode */}
      {!fullScreenEnabled && (
        <SideNavigation
          collapsed={sideNavCollapsed}
          classes={{
            root: classes.sideBar,
            rootCollapsed: classes.sideNavigationCollapsed,
          }}
          onCollapse={() => setSideNavCollapsed((prev) => !prev)}
        />
      )}

      <div
        className={cx(classes.app, {
          [classes.appNotFullScreen]: !fullScreenEnabled,
          [classes.appCollapsed]: !fullScreenEnabled && sideNavCollapsed,
          [classes.appFullScreen]: fullScreenEnabled,
          [classes.appNoTransition]: disableTransition,
        })}
      >
        {children}
      </div>
      {!!toasts.length && <div className={classes.toasts}>{toasts.map((item) => item.content)}</div>}
    </LoadScript>
  );
};

export const Layout = withStyles(styles)(LayoutComponent);
