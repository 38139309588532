import { createStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

import { colours, hexToRgba } from '@shared/theme/colours';
import { ButtonSize, NewButtonColour } from '@shared/types/common/button';

// TODO: Change all hexcodes when palette is updated

interface ButtonStyling {
  active: CSSProperties;
  default: CSSProperties;
  disabled: CSSProperties;
  hovered: CSSProperties;
}

const sharedDisabledStyling: CSSProperties = {
  backgroundColor: colours.neutral[100],
  borderColor: colours.neutral[100],
  boxShadow: 'none',
  color: colours.neutral[400],
};

export const coreButtonStyling = {
  [NewButtonColour.Danger]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: colours.error[50],
      borderColor: colours.error[700],
      boxShadow: 'none',
      color: colours.brandSalmon[700],
    },
    hovered: {
      backgroundColor: colours.error[100],
      borderColor: colours.error[700],
      boxShadow: 'none',
      color: colours.brandSalmon[700],
    },
    active: {
      backgroundColor: colours.error[200],
      borderColor: colours.error[700],
      boxShadow: 'none',
      color: colours.brandSalmon[700],
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: colours.solid.white,
    //   borderColor: colours.neutral[300],
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),

  [NewButtonColour.Ghost]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    hovered: {
      backgroundColor: colours.neutral[100],
      borderColor: colours.neutral[100],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    active: {
      backgroundColor: colours.neutral[200],
      borderColor: colours.neutral[200],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: 'transparent',
    //   borderColor: 'transparent',
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),

  [NewButtonColour.Outline]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: colours.solid.white,
      borderColor: colours.neutral[300],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    hovered: {
      backgroundColor: colours.neutral[50],
      borderColor: colours.brandNavy[900],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    active: {
      backgroundColor: colours.neutral[100],
      borderColor: colours.brandNavy[900],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: colours.solid.white,
    //   borderColor: colours.neutral[300],
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),

  [NewButtonColour.PrimaryNavy]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: colours.brandNavy[900],
      borderColor: colours.brandNavy[900],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    hovered: {
      backgroundColor: colours.brandNavy[900],
      borderColor: colours.brandNavy[900],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    active: {
      backgroundColor: colours.brandNavy[900],
      borderColor: colours.brandNavy[900],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: colours.neutral[100],
    //   borderColor: colours.neutral[100],
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),

  [NewButtonColour.PrimarySalmon]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: colours.brandSalmon[500],
      borderColor: colours.brandSalmon[500],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    hovered: {
      backgroundColor: colours.brandSalmon[600],
      borderColor: colours.brandSalmon[600],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    active: {
      backgroundColor: colours.brandSalmon[700],
      borderColor: colours.brandSalmon[700],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: colours.neutral[100],
    //   borderColor: colours.neutral[100],
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),

  [NewButtonColour.PrimaryTeal]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: colours.brandTeal[500],
      borderColor: colours.brandTeal[500],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    hovered: {
      backgroundColor: colours.brandTeal[600],
      borderColor: colours.brandTeal[600],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    active: {
      backgroundColor: colours.brandTeal[700],
      borderColor: colours.brandTeal[700],
      boxShadow: 'none',
      color: colours.solid.white,
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: colours.neutral[100],
    //   borderColor: colours.neutral[100],
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),

  [NewButtonColour.Subtle]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: colours.neutral[50],
      borderColor: colours.neutral[50],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    hovered: {
      backgroundColor: colours.neutral[100],
      borderColor: colours.neutral[100],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    active: {
      backgroundColor: colours.neutral[200],
      borderColor: colours.neutral[200],
      boxShadow: 'none',
      color: colours.neutral[700],
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: colours.neutral[100],
    //   borderColor: colours.neutral[100],
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),

  [NewButtonColour.Transparent]: (_: Theme): ButtonStyling => ({
    default: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
      color: colours.solid.white,
    },
    hovered: {
      backgroundColor: hexToRgba(colours.solid.white, 0.15),
      borderColor: hexToRgba(colours.solid.white, 0.05),
      boxShadow: 'none',
      color: colours.solid.white,
    },
    active: {
      backgroundColor: hexToRgba(colours.solid.white, 0.3),
      borderColor: hexToRgba(colours.solid.white, 0.05),
      boxShadow: 'none',
      color: colours.solid.white,
    },
    disabled: sharedDisabledStyling,
    // NOTE: Commented-out disabled styling as per Tommy's design components
    // disabled: {
    //   backgroundColor: colours.neutral[100],
    //   borderColor: colours.neutral[100],
    //   boxShadow: 'none',
    //   color: colours.neutral[400],
    //   cursor: 'not-allowed',
    // },
  }),
};

const currentColorStyling = {
  '& $buttonLabel i': { color: 'currentColor' },
  '& $buttonLabel svg': { color: 'currentColor' },
  '& $buttonLabel svg path': { stroke: 'currentColor' },
};

const getColourStyles = (theme: Theme, colour: NewButtonColour, iconColour: string) => {
  const coreStyles = coreButtonStyling[colour](theme);

  return {
    ...coreStyles.default,
    cursor: 'pointer',

    '& $buttonLabel i': { color: iconColour },
    '& $buttonLabel svg': { color: iconColour },
    '& $buttonLabel svg path': { stroke: iconColour },

    '&:hover': {
      ...coreStyles.hovered,
      cursor: 'pointer',

      '& $buttonLabel i': { color: iconColour },
      '& $buttonLabel svg': { color: iconColour },
      '& $buttonLabel svg path': { stroke: iconColour },
    },
    '&$buttonHovered': {
      ...coreStyles.hovered,
      cursor: 'pointer',

      '& $buttonLabel i': { color: iconColour },
      '& $buttonLabel svg': { color: iconColour },
      '& $buttonLabel svg path': { stroke: iconColour },
    },
    '&:active': {
      ...coreStyles.active,
      cursor: 'pointer',

      '& $buttonLabel i': { color: iconColour },
      '& $buttonLabel svg': { color: iconColour },
      '& $buttonLabel svg path': { stroke: iconColour },
    },
    '&$buttonActive': {
      ...coreStyles.active,
      cursor: 'pointer',

      '& $buttonLabel i': { color: iconColour },
      '& $buttonLabel svg': { color: iconColour },
      '& $buttonLabel svg path': { stroke: iconColour },
    },
    '&$buttonDisabled': {
      ...coreStyles.disabled,
      cursor: 'not-allowed',

      '& $buttonLabel i': { color: 'currentColor' },
      '& $buttonLabel svg': { color: 'currentColor' },
      '& $buttonLabel svg path': { stroke: 'currentColor' },
    },
  };
};

export const styles = (theme: Theme) => {
  return createStyles({
    root: {
      border: '1px solid transparent',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.07)',
      minWidth: 10,
      ...currentColorStyling,
    },
    buttonActive: {},
    buttonDisabled: {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
    buttonHovered: {},
    buttonLoading: {
      cursor: 'wait',
      pointerEvents: 'none',
    },

    buttonStartIcon: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
    buttonLabel: {
      fontWeight: 500,
    },
    buttonEndIcon: {
      marginLeft: theme.spacing(2),
      marginRight: 0,
    },

    // Colours
    [NewButtonColour.Danger]: getColourStyles(theme, NewButtonColour.Danger, 'currentColor'),
    [NewButtonColour.Ghost]: getColourStyles(theme, NewButtonColour.Ghost, '#7E8899'),
    [NewButtonColour.Outline]: getColourStyles(theme, NewButtonColour.Outline, '#7E8899'),
    [NewButtonColour.PrimaryNavy]: getColourStyles(theme, NewButtonColour.PrimaryNavy, 'currentColor'),
    [NewButtonColour.PrimarySalmon]: getColourStyles(theme, NewButtonColour.PrimarySalmon, 'currentColor'),
    [NewButtonColour.PrimaryTeal]: getColourStyles(theme, NewButtonColour.PrimaryTeal, 'currentColor'),
    [NewButtonColour.Subtle]: getColourStyles(theme, NewButtonColour.Subtle, '#7E8899'),
    [NewButtonColour.Transparent]: getColourStyles(theme, NewButtonColour.Transparent, 'currentColor'),

    // Sizes
    [ButtonSize.extraSmall]: {
      height: 24,
      padding: theme.spacing(0, 1.5),

      '& $buttonLabel': {
        fontSize: 12,
      },
    },
    [ButtonSize.small]: {
      height: 32,
      padding: theme.spacing(0, 3),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.mediumLegacy]: {
      height: 36,
      padding: theme.spacing(0, 3),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.medium]: {
      height: 40,
      padding: theme.spacing(0, 4),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.large]: {
      height: 48,
      padding: theme.spacing(0, 4),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
    // Icon sizes
    [ButtonSize.iconSmall]: {
      height: 32,
      padding: theme.spacing(3),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
    [ButtonSize.iconLarge]: {
      height: 40,
      padding: theme.spacing(2),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
    [ButtonSize.iconOnly]: {
      height: 32,
      width: 32,
      padding: 0,

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
  });
};
