import { CircularProgress, Button as MuiButton, ButtonProps as MuiButtonProps, TooltipProps } from '@material-ui/core';
import cx from 'classnames';
import { HTMLProps, useMemo } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Tooltip } from '@shared/components/tooltip';
import { ButtonColour, ButtonSize, NewButtonColour } from '@shared/types/common/button';

import { styles } from './button.styles';

const getButtonColourFromLegacy = (colour: ButtonColour): NewButtonColour => {
  switch (colour) {
    case ButtonColour.error:
      return NewButtonColour.Danger;

    case ButtonColour.secondaryGhost:
    case ButtonColour.secondaryGhost2:
    case ButtonColour.secondaryGhost3:
      return NewButtonColour.Ghost;

    case ButtonColour.secondary:
    case ButtonColour.secondaryColour:
      return NewButtonColour.Outline;

    case ButtonColour.primary:
      return NewButtonColour.PrimaryNavy;

    case ButtonColour.primarySpecial:
      return NewButtonColour.PrimarySalmon;

    case ButtonColour.teal:
      return NewButtonColour.PrimaryTeal;

    case ButtonColour.primaryGhost:
      return NewButtonColour.Transparent;
  }
};

export interface ButtonProps extends Omit<MuiButtonProps, 'classes' | 'color' | 'size'>, WithStyles<typeof styles> {
  active?: boolean;
  arrow?: boolean;
  colour?: ButtonColour | NewButtonColour;
  component?: React.ElementType;
  disabled?: boolean;
  hovered?: boolean;
  loading?: boolean;
  size?: ButtonSize;
  text?: React.ReactNode;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  target?: HTMLProps<HTMLAnchorElement>['target'];
}

const ButtonComponent: React.FC<ButtonProps> = ({
  active = false,
  arrow = true,
  children,
  classes,
  colour: suppliedColour,
  disabled = false,
  hovered = false,
  loading = false,
  size = ButtonSize.medium,
  text,
  tooltip,
  tooltipPlacement = 'left',
  ...buttonProps
}) => {
  const colour: NewButtonColour = useMemo(() => {
    // Default
    if (typeof suppliedColour === 'undefined') {
      return NewButtonColour.Outline;
    }
    // New
    if (Object.values(NewButtonColour).includes(suppliedColour as NewButtonColour)) {
      return suppliedColour as NewButtonColour;
    }

    // Legacy
    return getButtonColourFromLegacy(suppliedColour as ButtonColour);
  }, [suppliedColour]);

  const spinner = loading ? (
    <CircularProgress
      color={colour === NewButtonColour.PrimaryNavy ? 'secondary' : 'primary'}
      size={size === ButtonSize.extraSmall ? 12 : 15}
      style={{ marginLeft: 8 }}
    />
  ) : null;

  const content = (
    <MuiButton
      {...buttonProps}
      disabled={disabled}
      disableRipple
      classes={{
        root: cx(classes.root, classes[colour], classes[size], {
          [classes.buttonDisabled]: disabled,
          [classes.buttonActive]: active,
          [classes.buttonHovered]: hovered,
          [classes.buttonLoading]: loading,
        }),
        startIcon: classes.buttonStartIcon,
        label: classes.buttonLabel,
        endIcon: classes.buttonEndIcon,
      }}
    >
      {text || children} {spinner}
    </MuiButton>
  );

  if (tooltip) {
    return (
      <Tooltip arrow={arrow} placement={tooltipPlacement || undefined} title={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return content;
};

export const Button = withStyles(styles)(ButtonComponent);
