import { IconButton } from '@material-ui/core';
import { ChangeEvent, FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Avatar } from '@shared/components/avatar';
import { Flex } from '@shared/components/flex';
import { CloseIcon } from '@shared/icons/close';
import { IUser } from '@shared/models/IUser';

import { styles } from './PusherLegacyNotification.styles';

export interface PusherLegacyNotificationProps extends WithStyles<typeof styles> {
  headline: string;
  subline?: string;
  eventString: string;
  user: IUser;
  onClose: () => void;
  onClick: () => void;
}

const PusherLegacyNotificationComponent: FC<PusherLegacyNotificationProps> = ({
  classes,
  eventString,
  headline,
  subline,
  user,
  onClose,
  onClick,
}) => {
  const handleClose = (e: ChangeEvent<any>) => {
    e.stopPropagation();

    onClose();
  };
  return (
    <Flex autoWidth={false} className={classes.root} wrap="nowrap" onClick={onClick}>
      <Avatar
        image={user.image?.url ?? ''}
        isPro={user.pro}
        name={user.name}
        size={40}
        theme={{ backgroundColour: user.theme.backgroundColour, textColour: user.theme.textColour }}
      />

      <Flex autoWidth={false} direction="column" classes={{ root: classes.content }}>
        <Flex autoWidth={false} className={classes.heading} alignItems="flex-start" wrap="nowrap">
          <Flex autoWidth={false} classes={{ root: classes.headingTextContainer }} wrap="nowrap">
            <span className={classes.headingTitle}>{user.name}</span>
            <span className={classes.subline}>{eventString}</span>
          </Flex>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <Flex direction="column" autoWidth={false}>
          <span className={classes.headingTitle}>{headline}</span>
          {subline && <span className={classes.subline}>{subline}</span>}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const PusherLegacyNotification = withStyles(styles)(PusherLegacyNotificationComponent);
