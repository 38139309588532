import { Menu, MenuItem } from '@material-ui/core';
import { FC, useMemo, useRef, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Avatar } from '@shared/components/avatar';
import { Flex } from '@shared/components/flex';
import { Link } from '@shared/components/link';
import { AsIcon } from '@shared/icons/as';
import { DotsVerticalIcon } from '@shared/icons/dots-vertical';
import { LogoutIcon } from '@shared/icons/logout';
import { SettingsIcon } from '@shared/icons/settings';
import { UploadIcon } from '@shared/icons/upload';
import { UsersIcon } from '@shared/icons/users';
import { AppNav } from '@shared/models/config';
import { IUser } from '@shared/models/IUser';

import { styles } from './SideNavigationUserItem.styles';

export interface SideNavigationUserItemProps extends WithStyles<typeof styles> {
  user: IUser;
  appNav: AppNav;
}

const SideNavigationUserItemComponent: FC<SideNavigationUserItemProps> = ({ appNav, classes, user }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuToggleRef = useRef<HTMLDivElement | null>(null);

  const optionsConfig = useMemo(() => {
    const options = [];

    // TODO: Refactor this so it is powered by appNav.settings_items_order

    if (appNav.settings_data_management.visible) {
      options.push({
        icon: <UploadIcon />,
        label: appNav.settings_data_management.label,
        to: appNav.settings_data_management.url,
      });
    }

    if (appNav.settings_marketplace_preferences.visible) {
      options.push({
        icon: <AsIcon />,
        label: appNav.settings_marketplace_preferences.label,
        to: appNav.settings_marketplace_preferences.url,
      });
    }

    if (appNav.settings_teams.visible) {
      options.push({
        icon: <UsersIcon />,
        label: appNav.settings_teams.label,
        to: appNav.settings_teams.url,
      });
    }

    if (appNav.settings_style_guide.visible) {
      options.push({
        icon: <i className={`icon icon-layers ${classes.layerIcon}`} />,
        label: appNav.settings_style_guide.label,
        to: appNav.settings_style_guide.url,
      });
    }

    if (appNav.settings_profile.visible) {
      options.push({
        icon: <SettingsIcon />,
        label: appNav.settings_profile.label,
        to: appNav.settings_profile.url,
      });
    }

    if (appNav.settings_logout.visible) {
      options.push({
        icon: <LogoutIcon />,
        label: appNav.settings_logout.label,
        to: appNav.settings_logout.url,
      });
    }

    return options;
  }, [appNav]);

  const avatarContent = useMemo(
    () => (
      <Avatar
        classes={{ root: classes.avatarRoot, avatar: classes.avatar, noImage: classes.avatarNoImage }}
        image={user.image?.url ?? ''}
        name={user.name}
        size={20}
        theme={user.theme}
      />
    ),
    [user]
  );

  const renderedOptions = useMemo(
    () =>
      optionsConfig.map((option, index) => (
        <div key={option.label}>
          {optionsConfig.length > 1 && index === optionsConfig.length - 1 && <div className={classes.divider} />}
          <MenuItem classes={{ root: classes.menuItem }} disableRipple onClick={() => setMenuOpen(false)}>
            <Link to={option.to} classes={{ root: classes.itemLink }}>
              <Flex className={classes.itemIconContainer} alignItems="center">
                {option.icon}
              </Flex>
              <span className={classes.label}>{option.label}</span>
            </Link>
          </MenuItem>
        </div>
      )),
    [optionsConfig]
  );

  return (
    <>
      <div ref={menuToggleRef} onClick={() => setMenuOpen(true)} className={classes.root}>
        {avatarContent}
        <span className={classes.name}>{user.name}</span>
        <div className={classes.iconContainer}>
          <DotsVerticalIcon classes={{ root: classes.menuIcon }} />
        </div>
      </div>
      <Menu
        anchorEl={menuToggleRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {renderedOptions}
      </Menu>
    </>
  );
};

export const SideNavigationUserItem = withStyles(styles)(SideNavigationUserItemComponent);
