import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      border: `1px solid #D1D6DF`, //TODO replace after creating new palette
      background: colors.white.lightest,
      boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
      padding: spacing(4),
      gap: 12,
      width: '100%',
      cursor: 'pointer',
    },
    content: {
      display: 'flex',
      flex: '1 1 auto',
    },
    heading: {
      flex: 1,
    },
    headingTextContainer: {
      display: 'inline-flex',
      gap: 4,
    },

    headingTitle: {
      color: '#101828', // TODO replace after creating new palette
      fontWeight: 600,
    },
    subline: {
      color: '#515D70', //TODO replace after creating new palette
      fontWeight: 400,
    },
  });
