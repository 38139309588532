import { WithStyles, withStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { ComponentType, FC, HTMLAttributes, ReactNode, useMemo } from 'react';

import { Tooltip } from '@shared/components/tooltip';

import styles from './ellipsis.styles';

export interface EllipsisProps extends HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  component?: ComponentType;
  maxWidth?: string | number;
  text: ReactNode;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
  width?: string | number;
  withTooltip?: boolean;
}

const EllipsisComponent: FC<EllipsisProps> = ({
  classes,
  width,
  maxWidth,
  style,
  text,
  tooltipText,
  withTooltip,
  tooltipPlacement,
  ...otherProps
}) => {
  const titleNode = useMemo(() => {
    if (withTooltip && tooltipText) {
      return tooltipText;
    }

    if (text && text !== '-') {
      return text;
    }

    return '';
  }, [text, tooltipText, withTooltip]);

  const titleText = useMemo(() => {
    if (withTooltip && tooltipText) {
      return tooltipText;
    }

    if (typeof text === 'string' && text && text !== '-') {
      return text;
    }

    return '';
  }, [text, tooltipText, withTooltip]);

  if (withTooltip && titleNode) {
    return (
      <Tooltip
        arrow
        classes={{
          root: classes.root,
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        containerProps={otherProps}
        placement={tooltipPlacement}
        style={{ ...style, maxWidth, width }}
        title={titleNode}
      >
        <>{text}</>
      </Tooltip>
    );
  }

  return (
    <div {...otherProps} className={classes.root} style={{ ...style, maxWidth, width }} title={titleText}>
      {text}
    </div>
  );
};

EllipsisComponent.defaultProps = {
  withTooltip: true,
};

export const Ellipsis = withStyles(styles)(EllipsisComponent);
