import { ISocietyDisposalListItem } from '@apis/societyDisposalsApi.types';
import { LettingTransactionsListItem } from '@apis/transactionsLettingsApi.types';
import { SaleTransactionsListItem } from '@apis/transactionsSalesApi.types';
import { IComment } from '@shared/models/comment';
import { Company } from '@shared/models/company/company';
import { Contact } from '@shared/models/contact/contact';
import { IDisposalListItem } from '@shared/models/disposal/disposal';
import { SocietyAcquisitions } from '@shared/models/society/societyAcquisitions';
import { SocietyMember } from '@shared/models/society/societyMember';
import { ListUserItem } from '@shared/models/user';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import { IndexResponse } from '@shared/types/services';

export enum TaskFrequency {
  None = 'NONE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export enum TaskStatus {
  Unconfirmed = 0,
  Confirmed = 1,
  Attended = 2,
  ClientDidntShow = 3,
  AgentDidntShow = 4,
  Cancelled = 5,
}

// List

export const tasksListFilterParamKeys = [
  'assigned',
  'completed',
  'month',
  'my_teams',
  'types',
  'users',
  'year',
] as const;

export const tasksListParamKeys = [...tasksListFilterParamKeys] as const;

export type TasksListParams = PartialQuery<typeof tasksListParamKeys>;

export type TasksListPayload = QueryPayload<TasksListParams>;

export interface TaskListItem {
  alertText: string;
  all_day: NumberBoolean;
  event_confirmed: boolean;
  from: string;
  id: number;
  owners: any[];
  rrule_text: string | null;
  rrule: string | null;
  status: number;
  title: string;
  to: string;
  tooltip: string;
  types: string;
}

export interface TasksListMeta {
  calendar_subscriptions: {
    calendar_sync_link?: string;
    mine?: string;
    org?: string;
    team?: string;
  };
}

export type TasksListResponse = IndexResponse<TaskListItem, TasksListMeta>;

// Show

export interface TaskShowItem {
  all_day: NumberBoolean;
  assignee_string: string;
  awaiting_feedback: boolean | null;
  comments: IComment[];
  companies: Company[];
  contacts: Contact[];
  date_string: string;
  description: string;
  disposals: IDisposalListItem[];
  event_confirmed?: boolean;
  from: string;
  has_internal_matches: boolean;
  ics_download: string | null;
  id: number;
  interests: InterestItem[];
  letting_transactions: LettingTransactionsListItem[];
  notes: string;
  organisation_id: number;
  originally_from: string;
  originally_to: string;
  outcome_string: string | null;
  outcome: ViewingOutcome;
  owners: any[];
  repeat_ends_on: null;
  repeat_monthly_type: number;
  repeat_never_ends: boolean;
  requirements: any[];
  rrule_frequency: TaskFrequency;
  rrule_interval: number | null;
  rrule_monthlyByDay: string[] | null;
  rrule_monthlyGetByMonthDay: null;
  rrule_monthlySetPosition: null;
  rrule_text: string | null;
  rrule_until: string | null;
  rrule: string | null;
  sale_transactions: SaleTransactionsListItem[];
  society_acquisitions: SocietyAcquisitions[];
  society_disposals: ISocietyDisposalListItem[];
  society_members: SocietyMember[];
  status: TaskStatus | null;
  title: string;
  to: string;
  tooltip?: string;
  type_string: string;
  type: string;
  types?: string;
  users: ListUserItem[];
}

export type TaskShowResponse = TaskShowItem;

// Leave feedback

export type LeaveViewingFeedback = {
  comments: string;
  discounted_reason: number | null;
  outcome: ViewingOutcome;
  set_as_discounted: boolean;
};

export interface TasksLeaveFeedbackPayload extends BodyPayload<LeaveViewingFeedback> {
  id: Id;
}

// Create / update

export interface TaskListItemDTO {
  all_day: number;
  companies: string | null;
  comments?: string | null;
  contacts: string | null;
  description?: string | null;
  disposals: string | null;
  end: string;
  letting_transactions: string | null;
  notes: string | null;
  requirements: string | null;
  rrule: string | null;
  sale_transactions: string | null;
  society_acquisitions: string | null;
  society_disposals: string | null;
  society_members: string | null;
  start: string;
  title: string;
  type: Id;
  users: string | null;
  status?: NumberBoolean;
}

export type TasksCreatePayload = BodyPayload<Partial<TaskListItemDTO>>;

export interface UpdateTaskPayload extends BodyPayload<Partial<TaskListItemDTO>> {
  id: Id;
}

export interface InterestItem {
  id: number;
  disposal_id: number;
  key: string;
  value: string;
  tenant_name: string | null;
  status: number;
  status_string: string;
}
