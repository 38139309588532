import {
  FormControlLabel as MuiFormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './Switch.styles';

export enum SwitchType {
  default = 'default',
  square = 'square',
  round = 'round',
}

export enum SwitchSize {
  medium = 'medium',
  small = 'small',
}

export interface SwitchProps extends Omit<MuiSwitchProps, 'classes' | 'size'>, WithStyles<typeof styles> {
  label?: ReactNode;
  errorText?: string;
  error?: boolean;
  size?: SwitchSize;
  type?: SwitchType;
  tooltip?: string;
  tooltipPlacement?: MuiTooltipProps['placement'];
}

const SwitchComponent: FC<SwitchProps> = ({
  classes,
  label,
  size,
  type,
  tooltip,
  tooltipPlacement = 'top',
  ...switchProps
}) => {
  const switchContent = (
    <MuiSwitch
      {...switchProps}
      classes={{
        root: classes.switchRoot,
        colorPrimary: classes.switchPrimary,
        thumb: classes.switchThumb,
        switchBase: classes.switchBase,
        track: classes.switchTrack,
      }}
    />
  );

  const containerClass = cx(classes.root, type ? classes[type] : undefined, size ? classes[size] : undefined);

  let content = <div className={containerClass}>{switchContent}</div>;
  if (label) {
    content = (
      <MuiFormControlLabel
        classes={{ root: containerClass, label: classes.label }}
        control={switchContent}
        label={label}
      />
    );
  }

  if (tooltip) {
    return (
      <Tooltip
        placement={tooltipPlacement || undefined}
        title={tooltip}
        classes={{ container: classes.tooltipContainer }}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

export const Switch = withStyles(styles)(SwitchComponent);
